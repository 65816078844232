<template>
  <v-container class="text-center">
    <div class="bg-fade bg-purple bg-top-center"></div>
    <v-card class="mx-auto my-4" max-width="600" color="card">
      <v-card-text>
        <alert :text="alertText" :type="alertType" class="mb-6"></alert>
        <v-alert
          v-if="token && token.slug"
          class="text-left mb-6"
          color="info"
          outlined
          text
        >
          <v-list dense class="list">
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="!submitted" color="blue lighten-1">mdi-numeric-1-circle</v-icon>
                <v-icon v-if="submitted" color="green lighten-1">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content :class="submitted ? 'green--text' : 'blue--text'">
                <v-list-item-title>Submit to Metaview</v-list-item-title>
                <v-list-item-subtitle>You {{ submitted ? 'have been' : 'will be' }} entered into a draw to win a prize for top reviews.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="!confirmed" :color="tokenBalance > 0 ? 'blue' : 'grey'" class="text--lighten-1">mdi-numeric-2-circle</v-icon>
                <v-icon v-if="confirmed" color="green lighten-1">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content :class="confirmed ? 'green--text' : (tokenBalance > 0 ? 'blue--text' : 'grey--text')">
                <v-list-item-title>Submit to Blockchain</v-list-item-title>
                <v-list-item-subtitle v-if="tokenBalance == null && !$store.getters.account">Please connect your wallet.</v-list-item-subtitle>
                <v-list-item-subtitle v-if="tokenBalance == null && $store.getters.account && token_address">Connecting to blockchain...</v-list-item-subtitle>
                <v-list-item-subtitle v-if="confirmed || tokenBalance > 0">You {{ confirmed ? 'have received' : 'will receive' }} {{ this.token.reward }} VIEW tokens for submitting your review to the blockchain.</v-list-item-subtitle>
                <v-list-item-subtitle v-if="!confirmed && tokenBalance == 0 && network_supported">To receive {{ this.token.reward }} VIEW tokens you must have {{ this.token.symbol }} in your wallet.</v-list-item-subtitle>
                <v-list-item-subtitle v-if="!supported_platforms.length">This {{ this.token.category }} does not exist on any of our supported networks.</v-list-item-subtitle>
                <v-list-item-subtitle v-if="supported_platforms.length && !network_supported && $store.getters.account">Please {{ $store.getters.chainId ? 'switch' : 'connect' }} to one of our supported networks:
                  <br>
                  <v-btn
                    v-for="p in supported_platforms"
                    :key="p.chain_id"
                    :color="$store.getters.platformFor(p.chain_id)"
                    class="text-none mr-2 mt-2"
                    elevation="0"
                    rounded
                    x-small
                    @click="$store.dispatch('switchChain', p.chain_id)"
                  >
                    {{ $store.getters.platformName(p.chain_id) }}
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-alert>
        <v-form v-model="formValid">
          <v-combobox
            v-model="token"
            :items="tokens"
            label="Token"
            placeholder="Search"
            item-text="name"
            hide-details="auto"
            background-color="white"
            class="mb-3"
            outlined
            rounded
            @change="tokenChanged"
            :rules="[tokenValidation]"
            :search-input.sync="search"
            no-filter
          >
            <template v-slot:selection="{ item }">
              <v-chip
                v-if="item.name"
                color="grey lighten-4"
                class="token"
              >
                <v-img
                  :src="item.logo"
                  max-width="30"
                  max-height="30"
                  class="float-left mr-2"
                  contain
                  eager
                ></v-img>
                <span class="body-1 pr-2">
                  {{ item.name }}
                </span>
                <span class="body-2 pr-2 grey--text">
                  {{ item.symbol }}
                </span>
              </v-chip>
              <span
                v-if="tokenBalance"
                class="body-2 pr-2 grey--text"
              >
                Balance: {{ tokenBalance | toNumber }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <v-img
                :src="item.logo"
                max-width="30"
                max-height="30"
                class="float-left mr-2"
                contain
              ></v-img>
              <span class="body-1 pr-2">
                {{ item.name }}
              </span>
              <span class="body-2 pr-2 grey--text">
                {{ item.symbol }}
              </span>
            </template>
          </v-combobox>

          <v-rating
            v-model="rating"
            color="yellow darken-2"
            background-color="blue-grey lighten-3"
            class="mt-2 mb-4"
            :readonly="submitted || confirmed"
            x-large
            half-increments
            clearable
            @input="ratingChanged"
          ></v-rating>

          <v-chip
            v-if="rating"
            :color="ratingScale.color"
            class="mt-n2 mb-6"
          >
            {{ ratingScale.text }}
            <v-checkbox
              v-if="rating <= 2 || scam"
              v-model="scam"
              label="This token is a scam"
              color="red"
              class="scam pa-0 ms-2 mt-0"
              hide-details
            ></v-checkbox>
          </v-chip>

          <v-textarea
            v-model="review"
            label="Review"
            placeholder="Write your review here"
            hide-details="auto"
            class="mb-0"
            type="text"
            background-color="white"
            outlined
            height="200"
            :counter="1000"
            :rules="[reviewValidation]"
            validate-on-blur
            @blur="reviewChanged"
            :messages="reviewMessage"
            :disabled="submitted || confirmed"
          ></v-textarea>

          <wallet v-if="!$store.getters.account && !confirmed"></wallet>

          <v-btn
            v-if="$store.getters.account && !submitted"
            color="primary"
            class="text-none mb-4 px-6"
            :loading="confirming"
            :disabled="!formValidation()"
            rounded
            @click="submitReview"
          >
            Submit to Metaview
          </v-btn>

          <v-btn
            v-if="$store.getters.account && submitted && !confirmed"
            :color="$store.getters.platform"
            class="text-none mb-4 px-6"
            :loading="confirming"
            :disabled="!formValidation() || !token_address || tokenBalance == 0"
            rounded
            @click="getReward"
          >
            <v-avatar size="20" class="mr-2">
              <img :src="require(`@/assets/${$store.getters.platform}.svg`)" alt="Platform">
            </v-avatar>
            Submit to Blockchain
          </v-btn>

          <v-chip
            v-if="confirmed"
            :color="$store.getters.platform"
            :href="$store.getters.explorerUrl + '/tx/' + transaction"
            target="_blank"
            class="mb-4 pa-3"
          >
            <v-avatar left class="green lighten-4">
              <v-icon color="green">mdi-check</v-icon>
            </v-avatar>
            Confirmed. View transaction.
          </v-chip>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Alert from '../components/Alert.vue'
import Wallet from '../components/wallet/Wallet.vue'

export default {
  components: { Wallet, Alert },
  metaInfo() {
    return {
      title: this.token ? `${this.token.name} (${this.token.symbol})` : 'Token',
      titleTemplate: 'Review %s | Metaview',
    }
  },
  data: () => ({
    token: null,
    tokenBalance: null,
    tokens: [],
    search: '',
    formValid: false,
    rating: null,
    ratingValid: false,
    scam: false,
    review: null,
    loading: true,
    saved: false,
    saving: false,
    submitted: false,
    submitting: false,
    confirmed: false,
    confirming: false,
    reviewMessage: null,
    alertText: null,
    alertType: null,
    signature: null,
    transaction: null,
  }),
  computed: {
    token_address() {
      if(this.token && this.token.platforms) {
        var platform = this.token.platforms.filter((p) => {
          return p.chain_id == this.$store.getters.chainId
        })[0]
        return platform ? platform.address : null
      } else {
        return null
      }
    },
    supported_platforms() {
      var platforms = []
      if(this.token && this.token.platforms) {
        platforms = this.token.platforms.filter(p => p.supported == true)
      }
      return platforms
    },
    network_supported() {
      return this.supported_platforms.filter(p => p.chain_id == this.$store.getters.chainId).length > 0
    },
    ratingScale() {
      var scale = {
        0.5: { text: 'Bad',       color: 'red lighten-4' },
        1:   { text: 'Bad',       color: 'red lighten-5' },
        1.5: { text: 'Poor',      color: 'orange lighten-5' },
        2:   { text: 'Poor',      color: 'orange lighten-5' },
        2.5: { text: 'Average',   color: 'amber lighten-5' },
        3:   { text: 'Good',      color: 'yellow lighten-5' },
        3.5: { text: 'Great',     color: 'lime lighten-5' },
        4:   { text: 'Great',     color: 'lime lighten-5' },
        4.5: { text: 'Excellent', color: 'green lighten-5' },
        5:   { text: 'Excellent', color: 'green lighten-4' },
      }

      return scale[this.rating]
    },
  },
  mounted() {
    this.getTokens()
    this.getToken()
  },
  watch: {
    search: function(search) {
      if(search) {
        this.$store.commit('invalidateTokens')
        this.getTokens()
      }
    },
    rating: function() {
      this.ratingValid = this.ratingValidation() == true
      this.scam = this.rating > 2 ? false : this.scam
    },
    '$store.getters.chainId': function() {
      this.getBalance()
      this.getReview()
    },
    '$store.getters.account': function() {
      this.getBalance()
      this.getReview()
    },
  },
  methods: {
    getTokens() {
      this.loading = true
      this.$store.dispatch('getTokens', { page: 1, items: 10, sort: 'rank', desc: false, search: this.search }).then((response) => {
        this.tokens = response.data
        this.loading = false
      }).catch(() => {
        this.tokens = []
        this.loading = false
      })
    },
    getToken() {
      if(this.$route.params.slug) {
        this.loading = true
        this.$store.dispatch('getToken', this.$route.params.slug).then((token) => {
          this.token = token
          this.getBalance()
          this.getReview()
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    getBalance() {
      this.tokenBalance = null
      if (this.token && this.token_address && this.$store.getters.account) {
        var platform = this.token.platforms.filter((p) => { return p.chain_id == this.$store.getters.chainId })[0]
        var token_address = platform.address

        this.$store.dispatch('getBalance', token_address).then((balance) => {
          this.tokenBalance = balance
        }).catch(() => {
        })
      }
    },
    tokenChanged() {
      if(this.token && this.token.slug) {
        this.$router.push('/review/' + this.token.slug)
      } else {
        this.$router.push('/review')
      }
      this.clearReview()
      this.getBalance()
      this.getReview()
    },
    tokenValidation() {
      return Boolean(this.token)
    },
    ratingChanged() {
      if(this.formValidation()) {
        this.saveReview()
      }
    },
    ratingValidation() {
      return this.rating > 0 && this.rating <= 5 || 'Must be more than 0 and not more than 5'
    },
    reviewChanged() {
      if(this.formValidation()) {
        this.saveReview()
      }
    },
    reviewValidation() {
      return this.review && this.review.length >= 20 && this.review.length <= 1000 || 'Must be 20 to 1000 characters'
    },
    formValidation() {
      return (this.tokenValidation() && this.ratingValidation() == true && this.reviewValidation()) == true
    },
    getReview() {
      this.clearReview()

      var payload = {
        token_slug: this.token ? this.token.slug : null,
        user_address: this.$store.getters.account,
        chain_id: this.$store.getters.chainId,
        token_address: this.token_address,
      }

      if(payload.token_slug && payload.user_address) {
        this.loading = true
        this.$store.dispatch('getReview', payload).then((review) => {
          this.loading = false
          if(review) {
            this.saved = true
            this.rating = review.rating;
            this.scam = review.scam;
            this.review = review.review;
            this.signature = review.signature
            this.transaction = review.transaction
            this.reviewMessage = review.signature ? 'Submitted' : 'Draft saved'
            this.submitted = review.signature ? true : false
            this.confirmed = review.transaction ? true : false
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    clearReview() {
      this.alertText = null
      this.alertType = null
      this.saved = false
      this.rating = null
      this.scam = false
      this.review = null
      this.signature = null
      this.transaction = null
      this.reviewMessage = null
      this.submitted = false
      this.confirmed = false
    },
    saveReview() {
      var payload = {
        token_slug: this.token ? this.token.slug : null,
        user_address: this.$store.getters.account,
        chain_id: this.$store.getters.chainId,
        token_address: this.token_address,
        rating: this.rating,
        scam: this.scam,
        review: this.review,
        reward: this.token.reward,
        signature: this.signature,
        transaction: this.transaction,
      }

      if(payload.token_slug && payload.user_address) {
        this.saving = true
        this.reviewMessage = 'Saving...'
        this.$store.dispatch('saveReview', payload).then(() => {
          this.saving = false
          this.saved = true
          this.reviewMessage = payload.signature ? 'Submitted' : 'Draft saved'
        }).catch(() => {
          this.saving = false
          this.reviewMessage = 'Error saving review'
        })
      }
    },
    submitReview() {
      this.alertText = null
      this.alertType = null

      this.$store.dispatch('signMessage').then((signature) => {
        this.submitting = false
        this.submitted = true
        this.reviewMessage = null
        this.alertText = 'Your review has been successfully submitted.'
        this.alertType = 'success'
        this.signature = signature
        this.saveReview()
      }).catch((error) => {
        this.submitting = false
        this.alertText = error.data ? error.data.message : error.message
        this.alertType = 'error'
      })
    },
    getReward() {
      this.alertText = null
      this.alertType = null

      var payload = {
        token_address: this.token_address,
        user_address: this.$store.getters.account,
        rating: this.rating,
        scam: this.scam,
      }

      if(payload.token_address && payload.user_address && payload.rating) {
        this.confirming = true
        this.$store.dispatch('setRating', payload).then((receipt) => {
          this.confirming = false
          this.confirmed = true
          this.reviewMessage = null
          this.alertText = 'Your review has been successfully submitted to the blockchain.'
          this.alertType = 'success'
          this.transaction = receipt.transactionHash
          this.saveReview()
        }).catch((error) => {
          this.confirming = false
          this.alertText = error.data ? error.data.message : error.message
          this.alertType = 'error'
        })
      }
    },
  },
}
</script>

<style scoped>
.list >>> .v-list-item__icon {
  margin-right: 16px;
  margin-top: 10px;
}
.list >>> .v-list-item__icon .v-icon {
  font-size: 18px;
  width: 24px;
}
.list >>> .v-list-item__icon .v-icon::after {
  opacity: 0.16;
}
.list >>> .v-list-item__subtitle {
  font-weight: 400;
  white-space: normal;
  color: inherit !important;
  opacity: 0.7;
}
.token {
  margin: 0 10px 0 -10px;
  padding: 8px;
  height: 40px;
  border-radius: 20px;
}
.scam >>> .v-icon {
  font-size: 20px;
}
.scam >>> .v-label {
  font-size: 14px;
}
.scam >>> .v-input--selection-controls__input {
  margin-right: 2px;
}
.scam >>> .v-input--selection-controls__ripple {
  width: 24px;
  height: 24px;
  left: -7px;
  top: calc(50% - 19px);
}
</style>
