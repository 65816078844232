<template>
  <v-alert
    v-model="visible"
    class="text-left"
    prominent
    outlined
    text
    :type="type"
  >
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    visible: {
      get() {
        return Boolean(this.text && this.type)
      },
      set(value) {
        if (!value) {
          this.text = undefined
          this.type = undefined
        }
      },
    },
  },
}
</script>
